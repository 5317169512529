<template>
    <div class="flex flex-col items-center justify-center my-8 sm:my-15 p-5 overflow-x-hidden">
        <div class="flex flex-col sm:flex-row items-center justify-between w-full md:w-156 lg:w-239 2xl:w-322 mb-2.5 py-4">
            <div class="flex flex-col mb-5 sm:mb-5">
                <h2 class="text-2xl font-bold text-center sm:text-left">
                    {{ $t('text-latest-ads') }}
                </h2>
                <div class="text-base font-normal text-center sm:text-left">
                    {{ $t('text-latest-ads-subtitle') }}
                </div>
            </div>
            <div class="flex items-center justify-end space-x-5 flex-shrink-0 ml-5">
                <Button
                    variant="outline"
                    size="menu"
                    :on-click="handleShowMore"
                >
                    <span class="text-base">{{ $t('text-show-more') }}</span>
                </Button>
                <div class="flex items-center justify-end space-x-2">
                    <MenuButton
                        variant="base"
                        :disabled="page === 0"
                        :aria-label="$t('text-previous')"
                        :on-click="handlePrev"
                    >
                        <ChevronLeft class="w-3 h-3" />
                    </MenuButton>
                    <MenuButton
                        variant="base"
                        :disabled="Math.floor(pagination.itemCount / gridSize.width.value / 2) === page"
                        :aria-label="$t('text-next')"
                        :on-click="handleNext"
                    >
                        <ChevronRight class="w-3 h-3" />
                    </MenuButton>
                </div>
            </div>
        </div>
        <div v-if="loading || !isMounted" class="w-full flex justify-center">
          <div class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <ProductFeedLoader :limit="isMounted ? gridSize.width.value * 2 : 2" />
          </div>
        </div>
        <div v-else class="w-full flex justify-center">
          <div v-if="gridPage.length" class="w-full sm:w-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-9 gap-y-2.5 sm:gap-y-9">
            <RenderProductCard
                v-for="(item, idx) in gridPage"
                :key="item?.variant?.id"
                :show="item.show"
                :variant="item.variant"
                :class-name="item.hidden ? 'hidden' : ''"
                :loading="idx < 4 ? null : 'lazy'"
            />
          </div>
        </div>
        <div class="w-full flex items-center justify-center mt-6.5 sm:mt-9">
          <Button
                variant="outline"
                size="menu"
                :on-click="handleShowMore"
          >
                <span class="text-base">{{ $t('text-show-more') }}</span>
          </Button>
        </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth'
import { useSelectedStore } from '@stores/selected'
import Button from "@components/ui/button";
import MenuButton from "@components/ui/menu-button";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";
import ProductFeedLoader from "@components/ui/loaders/product-feed-loader";
import RenderProductCard from "@components/product/render-product-card";

const { $eventBus } = useNuxtApp();
const config = useRuntimeConfig();
const auth = useAuthStore();
const store = useSelectedStore();
const category = useState('route.category', () => 'aquarist');
const geolocation = useGeolocation();
const loading = ref(false);
const isMounted = ref(false);
const subtype = PRODUCT_SUBTYPE.main;
const page = ref(0);
const pages = useState(`products.${subtype}.pages.${category.value}`, () => []);
const pagination = useState(`products.${subtype}.pagination.${category.value}`, () => ({ page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 }));
const gridSize = useGridSize();
const variants = useState(`products.${subtype}.variants.${category.value}`, () => []);
const gridPage = ref([]);
const fader = useFader(variants, page, gridSize, gridPage);
const routeLang = useRouteLang();

const handleShowMore = async () => {
    await navigatorTo(translatePath(ROUTES.CODE.ADS));
}

const reload = async () => {
  if (loading.value) {
    return;
  }
  if (page.value === 0) loading.value = true;

  const body = _.pickBy({
    currentLocationLat: +(geolocation.coordinates.value.latitude || store.coordinates.latitude),
    currentLocationLng: +(geolocation.coordinates.value.longitude || store.coordinates.longitude)
  }, value => value !== null && value !== '' && value !== 0);

  try {
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.ESHOP_PRODUCT_VARIANT}`, {
      method: 'POST',
      params: {
        page: pagination.value.page,
        limit: pagination.value.itemsPerPage,
        locale: routeLang.locale.value
      },
      body
    });

    pages.value.push(data);
    pagination.value = data.pagination;
    fader.addVariants(data?.items || []);
    fader.scheduleShow(page.value, page.value);
  } catch (error) {
    if (import.meta.client && !auth.reloadTimeout) {
      // $toast.error(t('error.product-list.load'));
    }
    Sentry.captureException(error);
  }
  if (page.value === 0) loading.value = false;
}

watch(() => store.loadReset, async values => {
  if (values[subtype] && (import.meta.server || navigator?.onLine)) {
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
    fader.resetShow(gridSize.width.value);
    store.setLoadReset(subtype, false);
  }
}, { deep: true });

onServerPrefetch(async () => {
  pagination.value.page = 1;
  // await reload();
});

const variantUpdate = (value) => {
  const variant = _.find(variants.value, { variant: { id: value.id } });
  if (variant) {
    _.assignIn(variant.variant, value);
  }
  const gridItem = _.find(gridPage.value, { variant: { id: value.id } });
  if (gridItem) {
    _.assignIn(gridItem.variant, value);
  }
  if (variant || gridItem) {
    store.setLoadResetOthers();
  }
};

onMounted(async () => {
  isMounted.value = true;
  if ((_.isEmpty(pages.value) || store.loadReset[subtype] || auth.isLoggedIn) && (import.meta.server || navigator?.onLine)) {
    store.setLoadReset(subtype, false);
    fader.setVariants([]);
    pages.value = [];
    pagination.value.page = 1;
    await reload();
  }
  fader.resetShow(gridSize.width.value);
  $eventBus.on('variant:update', variantUpdate);
});

onUnmounted(() => {
  $eventBus.off('variant:update', variantUpdate);
});

const handlePrev = () => {
    page.value = Math.max(0, page.value - 1);
}

const handleNext = async () => {
    page.value = Math.min(Math.floor(pagination.value.itemCount / gridSize.width.value / 2), page.value + 1);
    if ((page.value + 1) * gridSize.width.value * 2 >= variants.value.length) {
        pagination.value.page++;
        await reload();
    }
}

</script>
