<template>
    <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_1004)">
    <path d="M1.15453 117.819C0.484397 118.264 0.297385 119.175 0.749333 119.845C1.19349 120.523 2.10518 120.695 2.77531 120.251C11.4091 114.492 35.9545 111.617 60.5 111.617C85.0454 111.617 109.591 114.492 118.225 120.251C118.903 120.687 119.806 120.516 120.251 119.845C120.703 119.167 120.516 118.264 119.845 117.819C110.697 111.718 85.5987 108.671 60.5 108.671C35.4013 108.671 10.3026 111.718 1.15453 117.819Z" fill="url(#paint0_radial_21_1004)"/>
    <path d="M81.6639 23.4792C79.8794 18.4766 78.0327 13.2948 76.4041 8.19092C76.1937 7.52079 75.5236 7.10001 74.8379 7.18572C74.1444 7.27144 73.5989 7.84027 73.5522 8.53377C72.9366 17.4714 74.0665 26.5026 75.1574 35.2377C75.4379 37.4351 75.7106 39.6325 75.9522 41.8143C77.4794 55.0455 78.7496 69.2273 76.0223 84.352C74.6587 91.8948 73.9418 98.5416 73.8327 104.658C73.8171 105.469 74.4639 106.131 75.2665 106.147H75.2898C76.0846 106.147 76.7392 105.508 76.7548 104.713C76.8639 98.7442 77.5574 92.2533 78.8976 84.8662C81.6872 69.352 80.4093 54.9208 78.8587 41.4792C78.6093 39.2818 78.3366 37.0844 78.0561 34.8714C77.3314 29.0896 76.5911 23.1753 76.3652 17.2922C77.1989 19.7078 78.0561 22.0922 78.8976 24.4455C80.8924 30.0325 82.9496 35.8065 84.7262 41.5494C84.96 42.3208 85.7781 42.7338 86.5574 42.5156C87.3288 42.274 87.7574 41.4559 87.5236 40.6844C85.7236 34.887 83.6587 29.0896 81.6561 23.4636L81.6639 23.4792Z" fill="url(#paint1_radial_21_1004)"/>
    <path d="M119.285 53.8533C118.591 53.713 117.921 54.1182 117.664 54.765C116.238 58.4663 114.368 62.0897 112.56 65.5962C110.635 69.3364 108.648 73.1936 107.137 77.2143C103.77 85.4039 101.822 94.3026 101.168 104.557C101.113 105.539 101.059 106.521 100.95 107.448C100.856 108.251 101.425 108.983 102.227 109.069C103.03 109.162 103.755 108.586 103.848 107.791C103.965 106.809 104.027 105.765 104.09 104.604C104.713 94.8169 106.591 86.2221 109.848 78.2819C111.313 74.3858 113.261 70.5988 115.147 66.9364C115.692 65.8767 116.253 64.7936 116.799 63.7026C116.246 66.8507 115.451 69.991 114.679 73.0689C113.814 76.4897 112.926 80.0351 112.357 83.565C110.924 92.4403 110.635 101.394 110.464 109.817C110.448 110.62 111.087 111.29 111.898 111.305H111.929C112.724 111.305 113.378 110.674 113.394 109.871C113.557 101.557 113.838 92.713 115.248 84.0325C115.794 80.6273 116.666 77.1442 117.516 73.7858C119.027 67.7858 120.586 61.591 120.477 55.2637C120.461 54.5702 119.955 53.9702 119.269 53.8533H119.285Z" fill="url(#paint2_radial_21_1004)"/>
    <path d="M84.2584 105.352C84.2351 106.155 84.8662 106.832 85.6766 106.864C86.5026 106.793 87.1571 106.256 87.1883 105.445C87.3987 98.8922 88.4117 91.9649 90.3675 83.674C91.8247 77.5727 93.3286 70.3182 93.3909 62.6818C93.4143 60.3675 93.2195 57.9363 93.0403 55.5909C92.8455 53.1441 92.6506 50.6506 92.6896 48.2272C93.1805 49.6532 93.7026 51.048 94.2247 52.435C95.4948 55.8324 96.8117 59.3545 97.5208 62.9545C99.2195 71.6818 97.7 79.9337 96.0948 88.661L95.5883 91.4428C94.5987 96.8428 93.9597 101.884 93.6948 106.427C93.6481 107.23 94.2636 107.923 95.0662 107.978H95.1519C95.9234 107.978 96.5701 107.378 96.6091 106.599C96.874 102.165 97.4974 97.248 98.4636 91.9571L98.9701 89.1831C100.622 80.1753 102.188 71.6662 100.388 62.3857C99.6403 58.5441 98.2766 54.913 96.9597 51.3987C95.6117 47.8065 94.3416 44.4169 93.6558 40.7857C93.5312 40.1311 92.9701 39.6402 92.3 39.5935C91.6065 39.5701 91.0221 39.9831 90.8195 40.622C89.3078 45.4844 89.7208 50.7363 90.1182 55.8013C90.3052 58.1935 90.4844 60.4532 90.461 62.6428C90.3987 69.9831 88.9416 77.0428 87.5156 82.9805C85.513 91.474 84.4766 98.5727 84.2584 105.336V105.352Z" fill="url(#paint3_radial_21_1004)"/>
    <path d="M35.7442 82.326C35.5105 83.1052 35.9468 83.9312 36.7182 84.1494C37.6533 84.4065 38.3546 83.8065 38.5416 83.1754C40.0299 78.2273 41.8143 71.6507 42.4066 64.5598C42.6014 62.2143 42.5858 59.7442 42.5702 57.3442C42.5546 54.9364 42.539 52.4897 42.7338 50.1208C43.1234 51.5624 43.5364 52.9806 43.9494 54.3832C44.8221 57.3208 45.7182 60.3598 46.2325 63.391C46.3728 64.1936 47.1442 64.7546 47.9234 64.5832C48.7182 64.4429 49.2559 63.6949 49.1234 62.9C48.578 59.6975 47.6507 56.5728 46.7546 53.5572C45.6715 49.8871 44.6429 46.4195 44.2143 42.7806C44.1364 42.1104 43.6143 41.5884 42.952 41.5026C42.2585 41.4247 41.6429 41.7987 41.4014 42.4221C39.5702 47.1208 39.6092 52.326 39.6403 57.3676C39.6559 59.8065 39.6715 62.1052 39.4923 64.3182C38.9234 71.1442 37.1858 77.5338 35.7442 82.3338V82.326Z" fill="url(#paint4_radial_21_1004)"/>
    <path d="M15.9282 28.467C16.3256 19.6773 16.7308 10.5838 15.3516 1.74748C15.2425 1.05397 14.6581 0.531894 13.9568 0.508518C13.2555 0.438388 12.6321 0.960466 12.4763 1.64618C11.2295 7.11631 9.74123 12.6877 8.29967 18.0722C4.48928 32.3319 0.546426 47.067 0.499672 62.1994C0.460711 69.9215 2.18279 77.5501 3.85811 84.9137C4.65292 88.4358 5.47889 92.0747 6.08669 95.628C6.83474 100.14 7.23214 104.838 7.29448 109.981C7.30227 110.784 7.95682 111.431 8.75941 111.431H8.775C9.58539 111.423 10.2321 110.753 10.2243 109.95C10.162 104.651 9.74902 99.8124 8.97759 95.1449C8.35422 91.5059 7.52045 87.8202 6.71785 84.267C5.08928 77.0592 3.39837 69.602 3.43733 62.2072C3.4763 47.4566 7.3724 32.9085 11.136 18.828C11.8529 16.1553 12.5776 13.4436 13.2789 10.7163C13.536 16.5838 13.2711 22.5215 13.014 28.3267C12.9127 30.5553 12.8114 32.776 12.7412 34.989C12.3127 48.5786 12.2269 63.1189 16.2477 78.2592C19.2088 89.3241 20.588 98.4722 20.588 107.036C20.588 107.838 21.2425 108.501 22.0529 108.501C22.8633 108.501 23.5179 107.846 23.5179 107.036C23.5179 98.2072 22.1075 88.8254 19.0763 77.5033C15.1646 62.7527 15.2503 48.4618 15.6711 35.0825C15.7412 32.8851 15.8425 30.6722 15.936 28.4592L15.9282 28.467Z" fill="url(#paint5_radial_21_1004)"/>
    <path d="M31.2248 19.3727C31.1468 18.6558 30.5624 18.1104 29.8378 18.0714C29.1209 18.0714 28.4897 18.5312 28.3339 19.2325C27.2118 24.5 25.7702 29.861 24.3832 35.035C22.3027 42.7727 20.1598 50.7753 18.952 58.8247C18.8352 59.6273 19.3806 60.3675 20.1832 60.4844C20.9468 60.6013 21.7261 60.0558 21.8429 59.2532C23.0196 51.3675 25.1468 43.4506 27.204 35.7909C27.7261 33.8506 28.2481 31.8948 28.7624 29.9234C28.7001 35.2221 28.2559 40.5208 27.8274 45.7182C26.83 57.7571 25.7936 70.2091 29.4092 82.4662C31.7079 90.1805 33.8196 97.7545 34.4429 105.469C34.5053 106.232 35.1442 106.809 35.9001 106.809C35.9391 106.809 35.978 106.809 36.0248 106.809C36.8274 106.747 37.4274 106.038 37.365 105.235C36.7183 97.2402 34.5598 89.5104 32.2222 81.6402C28.7702 69.913 29.7754 57.7337 30.7494 45.9675C31.4663 37.2714 32.2144 28.287 31.217 19.3805L31.2248 19.3727Z" fill="url(#paint6_radial_21_1004)"/>
    <path d="M61.7779 31.3338C60.9285 27.5001 60.0558 23.5338 59.6116 19.5988C59.5337 18.9052 58.9727 18.3676 58.2792 18.3052C57.5701 18.1884 56.9389 18.6871 56.7519 19.365C55.022 25.4663 54.9597 31.8871 54.8895 38.0897C54.8506 41.5494 54.8116 45.126 54.4766 48.539C53.5727 57.8819 51.4454 66.6403 49.1934 75.9208C48.7181 77.8845 48.2428 79.8481 47.7753 81.8351C45.8272 90.2273 44.6506 97.6689 44.1675 104.596C44.1129 105.399 44.7207 106.108 45.5311 106.155C45.5623 106.155 45.6012 106.155 45.6324 106.155C46.396 106.155 47.035 105.562 47.0895 104.791C47.5571 98.0195 48.7103 90.726 50.6194 82.4897C51.0869 80.5182 51.5623 78.5546 52.0376 76.5988C54.2116 67.6299 56.4636 58.3572 57.3908 48.8117C57.7337 45.2663 57.7727 41.6273 57.8116 38.1052C57.8428 34.8949 57.8818 31.6377 58.1467 28.4195C58.396 29.6039 58.661 30.7884 58.9181 31.9494C59.8142 35.9936 60.7337 40.1702 61.1545 44.3624C62.3311 52.7546 61.9571 61.5598 59.9934 71.2689C57.9753 81.6247 55.9337 92.791 54.648 104.222C54.5623 105.025 55.1389 105.757 55.9415 105.843C56.7363 105.921 57.461 105.36 57.5623 104.557C58.8324 93.2195 60.8662 82.1312 62.8688 71.8455C64.8869 61.8091 65.2766 52.6845 64.061 44.0195C63.6324 39.7182 62.6895 35.4481 61.7779 31.326V31.3338Z" fill="url(#paint7_radial_21_1004)"/>
    </g>
    <defs>
    <radialGradient id="paint0_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.5 114.584) rotate(90) scale(5.91247 59.9978)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint1_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.4826 56.6607) rotate(90) scale(49.486 7.10471)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint2_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(110.711 82.5654) rotate(90) scale(28.7398 9.77137)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint3_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(92.7238 73.7852) rotate(90) scale(34.1927 8.466)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint4_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(42.4123 62.8514) rotate(90) scale(21.359 6.73117)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint5_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.0084 55.9664) rotate(90) scale(55.4642 11.5094)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint6_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.1529 62.4402) rotate(90) scale(44.3688 9.2166)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint7_radial_21_1004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.4571 62.2214) rotate(90) scale(43.9332 10.293)">
    <stop offset="0.555" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <clipPath id="clip0_21_1004">
    <rect width="120" height="120" fill="white" transform="translate(0.5 0.5)"/>
    </clipPath>
    </defs>
    </svg>
</template>
