import _ from 'lodash-es';

export default function (variantsInput, pageInput, gridSizeInput, gridPageInput) {
  const duration = 800;
  const variants = variantsInput;
  const page = pageInput;
  const gridSize = gridSizeInput;
  const gridPage = gridPageInput;

  const setVariants = (values) => {
      variants.value = values;
  }

  const addVariants = (values) => {
      variants.value.push(..._.map(values, (variant) => ({
          variant,
          show: false,
          hidden: true
      })));
      if (!gridPage.value.length) {
        gridPage.value = variants.value.slice(0, 8);
      }
  }

  const resetShow = (gridSizeWidth) => {
      page.value = 0;
      _.forEach(variants.value, (item, idx) => {
          item.hidden = idx < page.value * gridSizeWidth * 2 || idx >= (page.value + 1) * gridSizeWidth * 2;
          item.show = !item.hidden;
      });
      gridPage.value = _.filter(variants.value, { show: true });
  }

  watch(() => gridSize.width.value, (value) => {
      resetShow(value);
  });

  const scheduleShow = (next, prev) => {
      if (next > prev) {
          _.forEach(gridPage.value, item => {
             item.show = false;
          });
          setTimeout(() => {
              _.forEach(gridPage.value, item => {
                 item.hidden = true;
              });
              gridPage.value = variants.value.slice(next * gridSize.width.value * 2, (next + 1) * gridSize.width.value * 2);
              _.forEach(gridPage.value, item => {
                item.hidden = false;
                item.show = false;
              });
              nextTick(() => {
                  _.forEach(gridPage.value, item => {
                      item.show = true;
                  });
              });
          }, duration);
      } else if (next < prev) {
          _.forEach(gridPage.value, item => {
             item.show = false;
          });
          setTimeout(() => {
              _.forEach(gridPage.value, item => {
                 item.hidden = true;
              });
              gridPage.value = variants.value.slice(next * gridSize.width.value * 2, (next + 1) * gridSize.width.value * 2);
              _.forEach(gridPage.value, item => {
                item.hidden = false;
                item.show = false;
              });
              nextTick(() => {
                  _.forEach(gridPage.value, item => {
                    item.show = true;
                  });
              });
          }, duration);
      } else if (import.meta.server) {
          resetShow(gridSize.width.value);
      }
  }

  watch(() => page.value, scheduleShow);

  return { setVariants, addVariants, resetShow, scheduleShow };
}
