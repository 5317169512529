<template>
    <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_1026)">
    <path d="M91.2325 12.8429V1.96494C91.2325 1.16234 90.5779 0.5 89.7675 0.5H31.2325C30.4221 0.5 29.7675 1.15455 29.7675 1.96494V12.8429C11.6974 23.5805 0.5 43.2169 0.5 64.3104C0.5 88.9026 15.2662 110.752 38.1208 119.962C39.0013 120.313 39.9831 120.5 40.9649 120.5H80.0351C81.0169 120.5 81.9987 120.313 82.8792 119.955C105.734 110.744 120.5 88.8948 120.5 64.3026C120.5 43.2169 109.31 23.5727 91.2325 12.8351V12.8429ZM32.6974 3.42987H88.3104V12.2117H32.6974V3.42987ZM81.7883 117.243C81.2506 117.461 80.6429 117.578 80.0273 117.578H40.9571C40.3493 117.578 39.7416 117.461 39.2039 117.251C17.4714 108.477 3.42987 87.7026 3.42987 64.3104C3.42987 44.1052 14.2377 25.3026 31.6532 15.1338H89.3545C106.77 25.3026 117.578 44.1052 117.578 64.3104C117.578 87.7026 103.529 108.484 81.7961 117.243H81.7883Z" fill="url(#paint0_radial_21_1026)"/>
    <path d="M80.9859 67.8169C85.5366 67.8169 91.1781 64.1156 95.1833 60.9364C96.4612 62.0273 97.5288 63.0169 98.2379 63.7338C98.8067 64.3026 99.734 64.3026 100.311 63.7338C100.887 63.165 100.879 62.2377 100.311 61.6611C100.17 61.5208 99.1028 60.4611 97.4664 59.0351C99.1106 57.6091 100.178 56.5494 100.311 56.4091C100.879 55.8403 100.879 54.913 100.311 54.3364C99.7418 53.7598 98.8145 53.7676 98.2379 54.3364C97.521 55.0533 96.4612 56.0429 95.1833 57.1338C91.1703 53.9468 85.5366 50.2455 80.9859 50.2455C73.1547 50.2455 65.6353 57.6792 65.3158 57.9909C64.747 58.5598 64.747 59.487 65.3158 60.0637C65.6353 60.3754 73.147 67.8091 80.9859 67.8091V67.8169ZM80.9859 53.1831C84.3989 53.1831 89.0898 56.113 92.8612 59.0351C89.0898 61.965 84.4067 64.887 80.9859 64.887C75.9677 64.887 70.7703 60.952 68.5262 59.0351C70.7625 57.1104 75.9521 53.1831 80.9859 53.1831Z" fill="url(#paint1_radial_21_1026)"/>
    <path d="M18.3832 30.313C10.5131 40.0065 6.35205 51.765 6.35205 64.3104C6.35205 86.5338 19.6923 106.248 40.3417 114.547C40.5131 114.617 40.7001 114.648 40.8871 114.648H80.1053C80.2923 114.648 80.4793 114.617 80.6508 114.547C101.3 106.248 114.64 86.5338 114.64 64.3104C114.64 51.765 110.479 40.0065 102.609 30.313C102.329 29.9702 101.916 29.7676 101.472 29.7676H19.5209C19.0845 29.7676 18.6637 29.9702 18.3832 30.313ZM79.8248 111.718H41.1754C38.7832 110.744 36.5001 109.607 34.3339 108.321C43.0456 107.433 52.3027 105.477 62.0274 103.42C74.2611 100.833 86.8767 98.191 99.6404 97.3806C94.4196 103.544 87.6871 108.508 79.8248 111.718ZM100.77 32.6974C107.939 41.7676 111.718 52.6845 111.718 64.3104C111.718 75.9364 108.22 85.7858 102.048 94.3104C88.178 94.9182 74.5806 97.778 61.4196 100.56C56.1598 101.674 51.0326 102.749 46.0767 103.646C47.9391 99.8585 49.0689 94.3026 50.23 88.4663C51.6248 81.4845 53.0741 74.2689 55.6845 71.6507C56.2533 71.0819 56.2533 70.1546 55.6845 69.578C55.1157 69.0091 54.1884 69.0091 53.6118 69.578C50.3702 72.8195 48.9053 80.1364 47.3547 87.8897C47.1832 88.7468 47.0118 89.6039 46.8326 90.4611C46.5131 87.6559 45.7417 84.6715 44.2456 81.6715C38.6897 70.5676 38.5806 59.4793 43.9728 54.0871C44.5417 53.5182 44.5417 52.591 43.9728 52.0143C43.404 51.4455 42.4767 51.4455 41.9001 52.0143C35.6196 58.2948 35.5105 70.739 41.6274 82.9728C44.9936 89.7052 44.2144 96.5078 43.1235 100.755C39.8508 92.6351 31.0534 80.0663 26.4092 75.4299C25.8404 74.8611 24.9131 74.8611 24.3365 75.4299C23.7676 75.9987 23.7676 76.926 24.3365 77.5026C29.8689 83.0351 39.9053 98.152 41.3001 104.448C37.5443 105.033 33.8975 105.477 30.3598 105.703C17.2611 96.2273 9.27413 81.0091 9.27413 64.2948C9.27413 52.6689 13.0533 41.752 20.2222 32.6819H100.77V32.6974Z" fill="url(#paint2_radial_21_1026)"/>
    </g>
    <defs>
    <radialGradient id="paint0_radial_21_1026" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.5 60.5) rotate(90) scale(60)">
    <stop offset="0.78" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint1_radial_21_1026" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.8146 59.0312) rotate(90) scale(8.78571 17.9255)">
    <stop offset="0.78" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <radialGradient id="paint2_radial_21_1026" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.4962 72.2078) rotate(90) scale(42.4403 54.1442)">
    <stop offset="0.78" stop-color="#FAEE45"/>
    <stop offset="1" stop-color="#A4FB7B"/>
    </radialGradient>
    <clipPath id="clip0_21_1026">
    <rect width="120" height="120" fill="white" transform="translate(0.5 0.5)"/>
    </clipPath>
    </defs>
    </svg>
</template>
